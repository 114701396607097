import React from 'react'
import styled from 'styled-components/macro'

import About from '../components/About'
import Features from '../components/Features'
import Hero from '../components/Hero'
import PartnerDetail from './PartnerDetail'
import Subscribe from '../components/Subscribe'
import Video from '../components/Video'
import ClubVideo from './ClubVideo'
import Partners from './Partners'

const Main = styled.main`
  overflow: hidden;
  padding-top: 8rem;
`

export default () => (
  <Main>
    <Hero />
    <PartnerDetail />
    <Partners />
    <ClubVideo />
    <About />
    <Video />
    <Features />
    <Subscribe />
  </Main>
)
