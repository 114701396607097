import React from 'react'
import styled from 'styled-components/macro'

type PropType = {
  children: JSX.Element | JSX.Element[] | string | string[]
  href?: string
  onClick?: any
}

const Link = styled.a`
  color: var(--color-tertiary);
  transition: opacity 0.3s;
`

export default ({ children, href = '', onClick }: PropType) => (
  <Link href={href} onClick={onClick}>
    {children}
  </Link>
)
