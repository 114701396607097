import React from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Container from '../components/Container'
import Description from '../components/Description'
import Title from '../components/Title'
import mockHero from '../assets/mock-hero.png'

const Hero = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 20rem;
  padding-top: 10rem;
  div {
    &:nth-child(1) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translateY(-2rem);
    }
    &:nth-child(2) {
      transform: translateX(5rem);
      margin-bottom: -0.6rem;
    }
  }
  h2 {
    line-height: 1.1;
    font-size: 4.2rem;
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 2rem;
  }
  button {
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-initial);
    height: 5.8rem;
    line-height: 5.6rem;
    margin: 0;
    padding: 0 10rem;
    text-transform: uppercase;
  }
  @media (min-width: 960px) {
    flex-direction: row;
    padding-top: 5rem;
    div {
      &:nth-child(1) {
        min-width: 58rem;
      }
      &:nth-child(2) {
        transform: translateX(9rem);
      }
    }
  }
`

export default () => (
  <Hero>
    <div>
      <div>
        <Title children="Segonha, minha espera segura" />
        <Description children="Plataforma de acompanhamento gestacional para mamães e obstetras" />
        <Button onClick={() => (window.location.href = '#cadastrar')}>
          Cadastrar Agora
        </Button>
      </div>
    </div>
    <div>
      <img alt="Segonha App" src={mockHero} />
    </div>
  </Hero>
)
