const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

export default {
  delete: (key: string): boolean => delete localStorage[key],
  get: (key: string): any =>
    base64regex.test(localStorage[key])
      ? JSON.parse(atob(localStorage[key]))
      : localStorage[key],
  set: (key: string, value: any): any =>
    (localStorage[key] = btoa(JSON.stringify(value))),
}
