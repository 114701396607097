import React from 'react'
import styled from 'styled-components/macro'

import Container from '../components/Container'

const Video = styled.div.attrs({
  id: 'video',
})`
  background: var(--color-initial);
  color: var(--color-primary);
  padding-bottom: 10rem;
  padding-top: 10rem;
  text-align: center;

  iframe {
    border: 4rem solid var(--color-secondary);
    border-radius: 0.5rem;
    height: 56rem;
    width: 80rem;
    max-width: 100%;
  }
`

export default () => (
  <Video>
    <Container>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        src="https://www.youtube-nocookie.com/embed/77mWuLcRGXA"
        title="Video da Segonha"
      ></iframe>
    </Container>
  </Video>
)
