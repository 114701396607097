import { useState } from 'react'

import api from '../utils/api'
import cpf from '../utils/cpf'
import phone from '../utils/phone'
import storage from '../utils/storage'

type PregnantType = {
  name: string
  email: string
  phone: string
  cpf: string
  type: string
}

export default (): [PregnantType, boolean, any, any, any] => {
  const initialValue = {
    name: '',
    email: '',
    phone: '',
    cpf: '',
    type: 'pregnant',
    ...storage.get('pregnant'),
  }

  const [pregnantForm, setPregnantForm] = useState({
    ...initialValue,
    ...storage.get('pregnant'),
  })

  const [pregnantToggle, setPregnantToggle] = useState(false)

  const handlePregnantChange = (event: any) => {
    event.preventDefault()
    let { name, value } = event.currentTarget
    if (name === 'cpf') value = cpf.parse(value)
    if (name === 'phone') value = phone.parse(value)
    storage.set('pregnant', { ...pregnantForm, [name]: value })
    setPregnantForm({ ...pregnantForm, [name]: value })
  }

  const handlePregnantSubmit = (event: any) => {
    event.preventDefault()
    api.setRegister(pregnantForm).then(result => {
      if (!result) return
      storage.delete('pregnant')
      window.location.reload()
    })
  }

  return [
    pregnantForm,
    pregnantToggle,
    setPregnantToggle,
    handlePregnantChange,
    handlePregnantSubmit,
  ]
}
