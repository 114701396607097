import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Icon from '../components/Icon'

type PropType = {
  className?: string
  description: string
  slides: Array<{
    icon: string
    image: string
    title: string
  }>
}

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  b,
  strong {
    color: var(--color-tertiary);
    display: block;
    font-size: 1.8rem;
    line-height: 2;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  b {
    display: none;
  }
  @media (min-width: 960px) {
    flex-direction: row-reverse;
    height: 80rem;
    text-align: left;
    h3 {
      display: block;
      padding-top: 11rem;
    }
    strong {
      display: none;
    }
    b {
      display: block;
    }
  }
`
const SliderTrack = styled.div`
  display: inline-block;
  margin-bottom: 2.5rem;
  overflow-x: scroll;
  max-width: 100%;
  white-space: nowrap;
  @media (min-width: 960px) {
    border: 0;
    display: block;
    margin: 0;
    overflow: visible;
    width: 60rem;
    white-space: normal;
  }
`
const SliderItem = styled.span`
  border-bottom: 0.5rem solid var(--color-tertiary);
  display: inline-block;
  margin-bottom: 2rem;
  &:hover,
  &.true {
    border-color: var(--color-primary);
  }
  @media (min-width: 960px) {
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    transform: translateY(0.7rem);
    transition: all 0.3s;
    &:hover,
    &.true {
      b {
        color: var(--color-primary);
      }
      svg {
        fill: var(--color-primary);
      }
    }
    b,
    img {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
`
const SliderIcon = styled(Icon)`
  cursor: pointer;
  fill: var(--color-tertiary);
  height: 6rem;
  margin: 0;
  padding: 1.2rem;
  transition: border 0.3s;
  @media (min-width: 960px) {
    border: 0.5rem solid transparent;
    margin: 0;
    padding: 0.6rem;
  }
`
const SliderImage = styled.div`
  img {
    max-width: 100%;
    width: 100%;
  }
  @media (min-width: 960px) {
    img {
      margin-left: -6.5rem;
      margin-top: -4rem;
      max-width: 50rem;
      transform: translateY(4rem);
      width: 50rem;
    }
  }
`

export default ({ className, description = '', slides }: PropType) => {
  const [slide, setSlide] = useState(slides[0] || {})

  if (!slides) return <></>
  return (
    <Slider className={className}>
      <div>
        <h3>{description}</h3>
        <SliderTrack>
          {slides.map(({ icon, title }, index) => (
            <SliderItem
              className={String(slide.icon === icon)}
              key={index}
              onClick={() => setSlide(slides[index])}
            >
              <SliderIcon className={String(slide.icon === icon)} type={icon} />
              <b>{title}</b>
            </SliderItem>
          ))}
        </SliderTrack>
        <strong>{slide.title}</strong>
        <br />
      </div>
      <SliderImage>
        <img alt={slide.title} src={slide.image} />
      </SliderImage>
    </Slider>
  )
}
