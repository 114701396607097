import React from 'react'
import styled from 'styled-components/macro'
import Container from './Container'
import Link from './Link'

import Title from './Title'

const Club = styled.div.attrs({
  id: 'clube-vantagens',
})`
  background: var(--color-secondary);
  color: var(--color-primary);
  text-align: center;
  position: relative;
  padding-bottom: 3.25rem;
  padding-top: 10rem;
  text-align: center;
`

const Description = styled.p`
  font-size: 2.1rem;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  position: relative;
`

const Button = styled.button`
  background: var(--color-primary);
  border-color: var(--color-primary);
  border-radius: var(--border-radius);
  color: var(--color-initial);
  line-height: 4.5rem;
  height: 4.5rem;
  margin-top: 3.25rem;
  text-transform: uppercase;
  transition: all 0.3s;
  &:hover {
    background: var(--color-quaternary);
    border-color: var(--color-quaternary);
  }
  @media (max-width: 400px) {
    font-size: 1rem;
  }
  @media (min-width: 401px) {
    font-size: 1.2rem;
  }
`
const ButtonLarge = styled(Button)`
  height: 4.8rem;
  line-height: 4.6rem;
  padding: 0 5rem;
`

export default () => (
  <Club>
    <Container>
      <div>
        <div>
          <Title children="O que é a rede de apoio Segonha?" />
          <Description children="São indicações de lojas e serviços que você e seu bebê podem utilizar em toda gestação e primeira infância do bebê. Sabemos que as necessidades de uma mãe e uma criança vão muito além de um acompanhamento pediátrico e obstétrico. " />
          <Description children="Pensando nas pequenas demandas diárias por serviços, fizemos um compilado com os melhores fornecedores para lhe ajudar nessa busca. " />
          <Description children="Com a Assinatura do Clube de vantagens, você receberá descontos exclusivos, além de dicas de quando utilizar o serviço em cada etapa. " />
          <Description children="Se cadastre no Segonha Rede de Apoio e tenha acesso à todos esses benefícios e conteúdos certificados. " />
          <Link href="https://clube-de-vantagens.segonha.com.br/">
            <Button>Acesse Agora!</Button>
          </Link>
        </div>
      </div>
    </Container>
  </Club>
)
