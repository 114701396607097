import React from 'react'
import styled from 'styled-components/macro'

import Container from '../components/Container'
import Description from '../components/Description'
import Tabs from '../components/Tabs'
import Title from '../components/Title'

const Features = styled.div.attrs({
  id: 'como-funciona',
})`
  background: var(--color-secondary);
  color: var(--color-tertiary);
  padding-top: 10rem;
  text-align: center;
  h2 {
    color: var(--color-primary);
  }
`

export default () => (
  <Features>
    <Container>
      <Title children="Como Funciona" />
      <Description children="Soluções completas para obstetras e gestantes" />
      <Tabs />
    </Container>
  </Features>
)
