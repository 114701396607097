import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Slider from '../components/Slider'
import mockFeature from '../assets/mock-feature.png'

const doctorDescription = ' Todas as funcionalidades que os obstetras precisam.'
const pregnantDescription =
  ' Todas as funcionalidades que as gestantes precisam.'
const doctorSlides = [
  {
    title: 'Integração obstetra - gestante',
    icon: 'doctor-integration',
    image: mockFeature,
  },
  {
    title: 'Calculos obstetricos',
    icon: 'doctor-calculator',
    image: mockFeature,
  },
  {
    title: 'Lembrete de vacinação, época de exames e ultrassons',
    icon: 'doctor-reminder',
    image: mockFeature,
  },
  {
    title: 'Controle do número de partos por mês',
    icon: 'doctor-control',
    image: mockFeature,
  },
  {
    title: 'Informações sempre à mão',
    icon: 'doctor-information',
    image: mockFeature,
  },
]
const pregnantSlides = [
  {
    title: 'Cartão pré natal 100% digital',
    icon: 'pregnant-prenatal',
    image: mockFeature,
  },
  {
    title: 'Lembretes do período para realização dos principais exames',
    icon: 'pregnant-reminder',
    image: mockFeature,
  },
  {
    title: 'Calendario de consultas ou outros eventos',
    icon: 'pregnant-calendar',
    image: mockFeature,
  },
  {
    title: 'Diário da gestante',
    icon: 'pregnant-notes',
    image: mockFeature,
  },
  {
    title: 'Acompanhamento gestacional - semana a semana',
    icon: 'pregnant-accompaniment',
    image: mockFeature,
  },
  {
    title: 'Armazenamento de exames',
    icon: 'pregnant-storage',
    image: mockFeature,
  },
]

const Tabs = styled.div``
const Group = styled.div`
  margin: 0 auto;
  text-align: center;
`
const Button = styled.div`
  align-items: center;
  border: solid 0.1rem var(--color-primary);
  border-left: 0;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.2rem;
  justify-content: center;
  height: 5rem;
  margin-bottom: 5rem;
  overflow: hidden;
  position: relative;
  width: 16rem;
  text-transform: uppercase;
  &:first-child {
    border-left: solid 0.1rem var(--color-primary);
    border-bottom-left-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
  }
  &:last-child {
    border-bottom-right-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  &:after {
    background: var(--color-primary);
    content: ' ';
    position: absolute;
    bottom: 0;
    height: 0.4rem;
    left: 0;
    opacity: 0;
    right: 0;
    transition: opacity 0.3s;
  }
  &.true,
  &:hover {
    &:after {
      opacity: 1;
    }
  }
`
const Content = styled.div`
  div {
    &.false {
      display: none;
    }
  }
`

export default () => {
  const [toggle, setToggle] = useState(true)

  return (
    <Tabs>
      <Group>
        <Button className={String(toggle)} onClick={() => setToggle(true)}>
          Obstetras
        </Button>
        <Button className={String(!toggle)} onClick={() => setToggle(false)}>
          Gestante
        </Button>
      </Group>
      <Content>
        <Slider
          className={String(toggle)}
          description={doctorDescription}
          slides={doctorSlides}
        />
        <Slider
          className={String(!toggle)}
          description={pregnantDescription}
          slides={pregnantSlides}
        />
      </Content>
    </Tabs>
  )
}
