import React from 'react'
import styled from 'styled-components/macro'

import Container from './Container'

const ClubVideo = styled.div.attrs({
  id: 'video',
})`
  background: var(--color-initial);
  color: var(--color-primary);
  padding-bottom: 10rem;
  text-align: center;

  iframe {
    border: 4rem solid var(--color-secondary);
    border-radius: 0.5rem;
    height: 56rem;
    width: 100rem;
    max-width: 100%;
  }
`

export default () => (
  <ClubVideo>
    <Container>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        src="https://www.youtube-nocookie.com/embed/zDtYOB2BDDA"
        title="Video do Segonha Rede de Apoio"
      ></iframe>
    </Container>
  </ClubVideo>
)
