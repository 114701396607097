import 'firebase/auth'
import 'firebase/database'
import { database, initializeApp } from 'firebase/app'

import cpfUtil from '../utils/cpf'
import phoneUtil from '../utils/phone'

export const firebase = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
})

export const post = async (reference: string = '', value: any = '') => {
  try {
    return await database()
      .ref(reference)
      .push({
        ...value,
        createdAt: +new Date(),
      })
  } catch (error) {
    alert(error)
  }
}

export const setNewsletter = async (email: string = '') => {
  if (!email) {
    alert('Por favor, o email precisa ser válido.')
    return
  }
  const response = await post(`/newsletter`, { email })
  alert('Cadastro concluído com sucesso.')
  return response
}

export const setRegister = async ({
  name = '',
  cpf = '',
  email = '',
  phone = '',
  crm = '',
  type,
}: {
  name: string
  cpf: string
  email: string
  phone: string
  crm?: string
  type: 'pregnant' | 'doctor'
}) => {
  if (!name || !cpf || !email || !phone || !type) {
    alert('Por favor, preencha todos os campos.')
    return
  }
  if (type === 'doctor' && !crm) {
    alert('Por favor, preencha o campo CRM.')
    return
  }
  if (!cpfUtil.validation(cpf)) {
    alert('Por favor, o CPF precisa ser válido.')
    return
  }
  if (!phoneUtil.validation(phone)) {
    alert('Por favor, o telefone precisa ser válido.')
    return
  }
  const payload = {
    name,
    cpf,
    email,
    phone,
    crm,
    type,
  }
  if (type === 'pregnant') delete payload.crm

  try {
    const response = await post(`/registers`, payload)
    alert('Cadastro concluído com sucesso.')

    return response
  } catch (error) {
    alert(error)
  }
}

export default {
  initialize: () => firebase,
  setNewsletter,
  setRegister,
}
