import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Container from '../components/Container'
import Icon from '../components/Icon'
import List from '../components/List'
import Link from '../components/Link'
import Modal from '../components/Modal'
import useNewsletter from '../hooks/newsletter'
import phone from '../utils/phone'

const Footer = styled.footer`
  background: var(--color-primary);
  color: var(--color-initial);
  padding: 5rem 0;
  * {
    fill: var(--color-initial) !important;
  }
  h3 {
    font-weight: var(--font-bold);
  }
  ul {
    margin-bottom: 6rem;
    &:first-child {
      margin-bottom: 2rem;
    }
  }
  li {
    margin-bottom: 0.3rem;
  }
  a {
    color: var(--color-initial);
    &:hover {
      color: var(--color-initial);
      opacity: 0.5;
    }
  }
`
const Sitemap = styled.nav`
  @media (min-width: 960px) {
    display: grid;
    grid-gap: 0 3rem;
    grid-template-columns: repeat(4, 1fr);
  }
`
const Logo = styled(Icon)`
  height: 20rem;
  position: relative;
  top: -3rem;
  &:hover {
    opacity: 0.5;
  }
`
const Item = styled.li``
const ItemSocial = styled(Item)`
  margin-top: 1rem;
  padding-top: 1rem;
  a {
    margin-right: 1rem;
  }
  svg {
    height: 2.8rem;
  }
  @media (min-width: 960px) {
    min-width: 19rem;
  }
`
const Newsletter = styled.form`
  display: flex;
  padding-top: 1rem;
  input {
    background: var(--color-initial);
    border: 0.1rem solid var(--color-quaternary);
    border-right: 0;
    border-radius: var(--border-radius);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
    padding: 1rem 1.5rem;
    position: relative;
    height: 5rem;
    transition: border 0.3s;
    &:active,
    &:focus {
      border: 0.1rem solid var(--color-primary);
    }
  }
  button {
    background: var(--color-primary);
    border: 0.1rem solid var(--color-initial);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: var(--color-initial);
    height: 5rem;
    margin: 0;
    width: 13rem;
  }
  @media (min-width: 960px) {
    form {
      margin-left: -2rem;
      padding: 0;
    }
    input {
      width: 22rem;
    }
  }
`
const Copyright = styled.div`
  margin-top: 6rem;
  text-align: center;
  @media (min-width: 960px) {
    margin-top: 0;
  }
`

export default () => {
  const [handleSubmit] = useNewsletter()
  const [privacy, setPrivacy] = useState(false)
  const [terms, setTerms] = useState(false)

  return (
    <Footer>
      <Container>
        <Sitemap>
          <List>
            <Item>
              <Link href="/">
                <Logo type="logo" />
              </Link>
            </Item>
          </List>
          <List>
            <Item>
              <h3>Institucional</h3>
            </Item>
            <Item>
              <Link href="#clube-vantagens">Clube de Vantagens</Link>
            </Item>
            <Item>
              <Link href="#quem-somos">Quem somos</Link>
            </Item>
            <Item>
              <Link href="#como-funciona">Como funciona</Link>
            </Item>
            {/* <Item>
              <Link href="#termo-de-uso" onClick={() => setTerms(!terms)}>
                Termos de uso
              </Link>
            </Item> */}
            <Item>
              <Link
                href="#politica-de-privacidade"
                onClick={() => setPrivacy(!privacy)}
              >
                Política de privacidade
              </Link>
            </Item>
          </List>
          <List>
            <Item>
              <h3>Contato</h3>
            </Item>
            <Item>
              {process.env.REACT_APP_SOCIAL_PHONE && (
                <Link href={`tel:${process.env.REACT_APP_SOCIAL_PHONE}`}>
                  {phone.parse(process.env.REACT_APP_SOCIAL_PHONE)}
                </Link>
              )}
            </Item>
            <Item>
              {process.env.REACT_APP_EMAIL && (
                <Link href={`mailto:${String(process.env.REACT_APP_EMAIL)}`}>
                  {String(process.env.REACT_APP_EMAIL)}
                </Link>
              )}
            </Item>
            <ItemSocial>
              {process.env.REACT_APP_SOCIAL_WHATSAPP && (
                <Link
                  href={`https://wa.me/${process.env.REACT_APP_SOCIAL_WHATSAPP}`}
                >
                  <Icon type="whatsapp" />
                </Link>
              )}
              {process.env.REACT_APP_SOCIAL_INSTAGRAM && (
                <Link href="https://instagram.com/minhasegonha">
                  <Icon type="instagram" />
                </Link>
              )}
              {process.env.REACT_APP_SOCIAL_FACEBOOK && (
                <Link href="https://facebook.com/minhasegonha">
                  <Icon type="facebook" />
                </Link>
              )}
              {process.env.REACT_APP_SOCIAL_TWITTER && (
                <Link href="https://twitter.com/minhasegonha">
                  <Icon type="twitter" />
                </Link>
              )}
              {process.env.REACT_APP_SOCIAL_LINKEDIN && (
                <Link href="https://linkedin.com/in/minhasegonha">
                  <Icon type="linkedin" />
                </Link>
              )}
            </ItemSocial>
          </List>
          <List>
            <Item>
              <h3>Newsletter</h3>
            </Item>
            <Item>
              Receba as últimas notícias diretamente na sua caixa de email.
            </Item>
            <Item>
              <Newsletter onSubmit={handleSubmit}>
                <input
                  id="email"
                  name="email"
                  placeholder="Digite seu email"
                  required
                  type="email"
                />
                <Button>Assinar</Button>
              </Newsletter>
            </Item>
          </List>
        </Sitemap>
        <Copyright>
          Plataforma de acompanhamento gestacional para mamães e obstetras.
          <br />
          Feito com ♥ | Segonha – Copyright © {new Date().getFullYear()}
        </Copyright>
      </Container>
      {terms && (
        <Modal onClick={() => setTerms(!terms)} title="termos de uso">
          <>
            <p>What is Lorem Ipsum?</p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>Why do we use it?</p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
            <p>Where does it come from?</p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
              Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
              BC. This book is a treatise on the theory of ethics, very popular
              during the Renaissance. The first line of Lorem Ipsum, "Lorem
              ipsum dolor sit amet..", comes from a line in section 1.10.32.
            </p>
            <p>
              The standard chunk of Lorem Ipsum used since the 1500s is
              reproduced below for those interested. Sections 1.10.32 and
              1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also
              reproduced in their exact original form, accompanied by English
              versions from the 1914 translation by H. Rackham.
            </p>
            <p>Where can I get some?</p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour, or non-characteristic words etc.
            </p>
          </>
        </Modal>
      )}
      {privacy && (
        <Modal
          onClick={() => setPrivacy(!privacy)}
          title="Política de privacidade"
        >
          <>
            <p>
              O presente termo visa descrever e explicar aos usuários do Segonha
              como funciona a política de privacidade e como serão utilizadas e
              processadas as informações fornecidas por eles. A privacidade do
              usuário é fundamental e entendemos nossa responsabilidade de
              proteger e salvaguardar os dados pessoais coletados.
            </p>
            <h5>
              <b>Recolhimento e utilização dos dados pessoais</b>
            </h5>
            <p>
              Dados pessoais são aqueles que identificam os usuários, como nome,
              e-mail, cidade, estado, celular de contato, CPF, data de
              nascimento, estado civil, profissão, dentre outras informações que
              dizem a respeito da pessoa do usuário.
            </p>
            <p>
              As informações serão utilizadas para concluir suas solicitações e
              permitir o uso de certas funcionalidades da Plataforma Segonha,
              entrar em contato com o usuário para confirmar ou verificar as
              informações fornecidas, e conduzir diligências dos processos
              internos da empresa.
            </p>
            <p>
              Todas as informações pessoais só serão coletadas quando o usuário
              decidir fornecê-las, permitindo sua utilização (ex: fornecer o
              e-mail antes da verificação de elegibilidade do problema), e serão
              mantidas apenas durante o tempo necessário para a prestação do
              serviço.
            </p>
            <p>
              Nenhum dos dados pessoais serão fornecidos ou compartilhados com
              terceiros. No entanto, o Segonha poderá compartilhar dados
              pessoais em caso de requisição judicial ou governamental.
            </p>
            <p>Informações colhidas automaticamente (dados não pessoais)</p>
            <p>
              Poderemos coletar informações e dados sobre a sua experiência de
              navegação em nossa Plataforma. Ao longo de sua visita na
              Plataforma Segonha, poderão ser automaticamente coletados o seu
              Endereço de Protocolo da Internet (IP), seu tipo de computador,
              tipo de celular, tipo de navegador, páginas e serviços acessados,
              entre outros.
            </p>
            <p>
              Todas essas informações e dados são geradas com caráter anônimo e
              servem para melhorar o conteúdo oferecido e proporcionar uma
              melhor gestão interna.
            </p>
            <p>
              O Segonha poderá compartilhar Informações não pessoais para gerar
              e divulgar estatísticas em redes sociais, na mídia ou junto a
              parcerias comerciais. Isso poderá incluir informações, dados e
              tendências demográficas. Em todos os casos tais informações terão
              caráter anônimo
            </p>
            <h5>
              <b>Cookies</b>
            </h5>
            <p>
              Os cookies são pequenos arquivos de texto criados pelo site e
              salvos no computador ou dispositivo móvel de um usuário, por um
              determinado período de tempo, no momento que há o acesso à
              plataforma. Eles podem apontar se é a primeira vez do usuário em
              nossa plataforma ou não, quais as preferências do usuário (como
              login, tamanho da fonte, linguagem e outras) ou qual página do
              nosso site já foi visitada anteriormente. Importante destacar que
              os cookies não possibilitam a identificação do usuário e não
              armazenam dados pessoais do visitante.
            </p>
            <p>
              Se não desejar o uso de tais cookies, basta configurar seu
              navegador para apagar todos os cookies do seu computador, bloquear
              todos os cookies ou para receber um aviso antes que um cookie seja
              armazenado. No entanto, eliminar ou bloquear os cookies terá um
              impacto negativo na normalidade de funcionamento de vários
              websites. Caso opte por bloquear os nossos cookies, alguns
              serviços e funcionalidades poderão não funcionar.
            </p>
            <p>
              O armazenamento dos dados e das informações mencionadas será
              feito/realizado/procedido sob as mais rígidas práticas de
              segurança da informação. Aplicamos ao nosso banco de dados ações
              internas e externas para a completa proteção dos dados
              armazenados.
            </p>
            <h5>
              <b>Atualização ou alteração</b>
            </h5>
            <p>
              As diretrizes contidas na política de privacidade podem ser
              alteradas ou atualizadas a qualquer momento, sem aviso prévio.
              Orientamos os usuários do Segonha que fiquem cientes do texto
              atualizado por meio da leitura desta seção.
            </p>
          </>
        </Modal>
      )}
    </Footer>
  )
}
