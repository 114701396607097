import React from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Container from '../components/Container'
import Description from '../components/Description'
import Modal from '../components/Modal'
import Title from '../components/Title'
import useDoctor from '../hooks/doctor'
import usePregnant from '../hooks/pregnant'
import cpf from '../utils/cpf'
import phone from '../utils/phone'

const Subscribe = styled(Container).attrs({
  id: 'cadastrar',
})`
  color: var(--color-tertiary);
  padding-bottom: 10rem;
  padding-top: 10rem;
  text-align: center;
  h2 {
    color: var(--color-tertiary);
  }
  button {
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-initial);
    height: 5.8rem;
    line-height: 5.6rem;
    margin: 0;
    padding: 0 10rem;
    text-transform: uppercase;
  }
`
const Grid = styled.div`
  @media (min-width: 960px) {
    display: grid;
    grid-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }
`
const Item = styled.div.attrs({
  className: 'Item',
})`
  & + .Item {
    margin-top: 6rem;
  }
  @media (min-width: 960px) {
    & + .Item {
      margin-top: 0;
    }
  }
`
const RegisterForm = styled.form`
  margin-bottom: -2rem;
  input {
    background: var(--color-initial);
    border: 0.1rem solid var(--color-primary);
    border-radius: 0.6rem;
    color: var(--color-primary);
    margin: 0 0 var(--margin-bottom);
    padding: 1rem 1.5rem;
    position: relative;
    height: 5rem;
    transition: border 0.3s;
    &:active,
    &:focus {
      border: 0.1rem solid var(--color-tertiary);
      color: var(--color-tertiary);
    }
    &::placeholder {
      color: var(--color-primary);
    }
  }
`

export default () => {
  const [
    doctorForm,
    doctorToggle,
    setDoctorToggle,
    handleDoctorChange,
    handleDoctorSubmit,
  ] = useDoctor()
  const [
    pregnantForm,
    pregnantToggle,
    setPregnantToggle,
    handlePregnantChange,
    handlePregnantSubmit,
  ] = usePregnant()

  return (
    <Subscribe>
      <Title children="Cadastrar" />
      <Description children="Cadastre-se para ser o primeiro a ficar por dentro dessa novidade" />
      <Grid>
        <Item>
          <h3>Obstetra</h3>
          <p>
            Cadastre-se para ser o primeiro a testar de forma gratuita essa
            plataforma 100% pensada para você. A ferramenta disponibiliza
            informações diretamente com sua paciente e facilita a vida de ambos.
          </p>
          <Button onClick={() => setDoctorToggle(!doctorToggle)}>
            Cadastrar
          </Button>
        </Item>
        <Item>
          <h3>Gestante</h3>
          <p>
            O Segonha tem o objetivo de dar segurança. Dessa forma, utilizamos a
            tecnologia para aproximar você ao seu obstetra e trazer informações
            confiáveis para dar fim às incertezas no período gestacional. <br />
            Cadastre-se para testar de forma gratuita.
          </p>
          <Button onClick={() => setPregnantToggle(!pregnantToggle)}>
            Cadastrar
          </Button>
        </Item>
      </Grid>
      {doctorToggle && (
        <Modal
          onClick={() => setDoctorToggle(!doctorToggle)}
          title="Formulário de Cadastro do Obstetra"
        >
          <>
            <RegisterForm onSubmit={handleDoctorSubmit}>
              <input
                value={doctorForm?.name}
                id="name"
                name="name"
                placeholder="Digite seu nome"
                onChange={handleDoctorChange}
                required
                type="text"
              />
              <input
                value={doctorForm?.email}
                id="email"
                name="email"
                placeholder="Digite seu email"
                onChange={handleDoctorChange}
                required
                type="email"
              />
              <input
                value={phone.parse(doctorForm?.phone)}
                id="phone"
                name="phone"
                placeholder="Digite seu telefone"
                onChange={handleDoctorChange}
                onInput={({ currentTarget }: any) => {
                  currentTarget.value = phone.parse(String(currentTarget.value))
                }}
                required
                type="text"
              />
              <input
                value={doctorForm?.cpf}
                id="cpf"
                name="cpf"
                placeholder="Digite seu cpf"
                onChange={handleDoctorChange}
                onInput={({ currentTarget }: any) => {
                  currentTarget.value = cpf.parse(String(currentTarget.value))
                }}
                required
                type="text"
              />
              <input
                value={doctorForm?.crm}
                id="crm"
                name="crm"
                placeholder="Digite seu CRM"
                onChange={handleDoctorChange}
                required
                type="text"
              />
              <input
                value={doctorForm?.type}
                id="type"
                name="type"
                onChange={handleDoctorChange}
                required
                type="hidden"
              />
              <Button>Enviar</Button>
            </RegisterForm>
          </>
        </Modal>
      )}
      {pregnantToggle && (
        <Modal
          onClick={() => setPregnantToggle(!pregnantToggle)}
          title="Formulário de Cadastro da Gestante"
        >
          <>
            <RegisterForm onSubmit={handlePregnantSubmit}>
              <input
                value={pregnantForm?.name}
                id="name"
                name="name"
                placeholder="Digite seu nome"
                onChange={handlePregnantChange}
                required
                type="text"
              />
              <input
                value={pregnantForm?.email}
                id="email"
                name="email"
                placeholder="Digite seu email"
                onChange={handlePregnantChange}
                required
                type="email"
              />
              <input
                value={phone.parse(pregnantForm?.phone)}
                id="phone"
                name="phone"
                placeholder="Digite seu telefone"
                onChange={handlePregnantChange}
                onInput={({ currentTarget }: any) => {
                  currentTarget.value = phone.parse(String(currentTarget.value))
                }}
                required
                type="text"
              />
              <input
                value={pregnantForm?.cpf}
                id="cpf"
                name="cpf"
                placeholder="Digite seu cpf"
                onChange={handlePregnantChange}
                onInput={({ currentTarget }: any) => {
                  currentTarget.value = cpf.parse(String(currentTarget.value))
                }}
                required
                type="text"
              />
              <input
                value={pregnantForm?.type}
                id="type"
                name="type"
                onChange={handlePregnantChange}
                required
                type="hidden"
              />
              <Button>Enviar</Button>
            </RegisterForm>
          </>
        </Modal>
      )}
    </Subscribe>
  )
}
