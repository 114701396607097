import React from 'react'
import styled from 'styled-components/macro'

type PropType = {
  children: JSX.Element | JSX.Element[] | string | string[]
  type?: string
  date?: string | number
}

const List = styled.ul`
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }
`

export default ({ children, type = '' }: PropType) =>
  type === 'ordered' ? (
    <ol>{children}</ol>
  ) : type === 'unordered' ? (
    <ul>{children}</ul>
  ) : (
    <List>{children}</List>
  )
