import { useState } from 'react'

import api from '../utils/api'
import cpf from '../utils/cpf'
import phone from '../utils/phone'
import storage from '../utils/storage'

type DoctorType = {
  name: string
  email: string
  phone: string
  cpf: string
  crm: string
  type: string
}

export default (): [DoctorType, boolean, any, any, any] => {
  const initialValue = {
    name: '',
    email: '',
    phone: '',
    cpf: '',
    crm: '',
    type: 'doctor',
    ...storage.get('doctor'),
  }

  const [doctorForm, setDoctorForm] = useState({
    ...initialValue,
    ...storage.get('doctor'),
  })

  const [doctorToggle, setDoctorToggle] = useState(false)

  const handleDoctorChange = (event: any) => {
    event.preventDefault()
    let { name, value } = event.currentTarget
    if (name === 'cpf') value = cpf.parse(value)
    if (name === 'phone') value = phone.parse(value)
    storage.set('doctor', { ...doctorForm, [name]: value })
    setDoctorForm({ ...doctorForm, [name]: value })
  }

  const handleDoctorSubmit = (event: any) => {
    event.preventDefault()
    api.setRegister(doctorForm).then(result => {
      if (!result) return
      storage.delete('doctor')
      window.location.reload()
    })
  }

  return [
    doctorForm,
    doctorToggle,
    setDoctorToggle,
    handleDoctorChange,
    handleDoctorSubmit,
  ]
}
