import React, { useState } from 'react'
import styled from 'styled-components/macro'
import ReactSimplyCarousel from 'react-simply-carousel'

import bossaBessa from '../assets/bossa-bessa.jpeg'
import animar from '../assets/animar.png'
import te from '../assets/logo-te.png'
import briejer from '../assets/briejer.png'
import itaSaude from '../assets/ita-saude.png'
import cmProjetar from '../assets/cmprojetar.png'
import maternoFetal from '../assets/materno-fetal.png'

const Container = styled.div`
  max-width: 133rem;
  margin: auto;
  pointer-events: none;
`

const Box = styled.div`
  padding: 10px;
  pointer-events: none;
`

const Image = styled.img`
  max-width: 400px;
  width: auto;
  height: 80px;
  pointer-events: none;
`

export default () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  return (
    <Container>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        autoplay={true}
        autoplayDelay={3000}
        forwardBtnProps={{ style: { border: 'none', height: 0, width: 0 } }}
        backwardBtnProps={{ style: { border: 'none', height: 0, width: 0 } }}
        responsiveProps={[{ itemsToScroll: 1 }]}
        speed={400}
        easing="linear"
      >
        <Box>
          <Image src={bossaBessa} />
        </Box>
        <Box>
          <Image src={animar} />
        </Box>
        <Box>
          <Image src={te} />
        </Box>
        <Box>
          <Image src={briejer} />
        </Box>
        <Box>
          <Image src={cmProjetar} />
        </Box>
      </ReactSimplyCarousel>
    </Container>
  )
}
