import { initialize, pageview, set } from 'react-ga'

import isLocalhost from '../utils/localhost'

if (!isLocalhost) {
  initialize(String(process.env.REACT_APP_TRACKING || ''))
}

export default (page: any) => {
  set({ page })
  pageview(page)
}
