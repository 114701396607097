import React from 'react'
import styled from 'styled-components/macro'

type PropType = {
  children: string
}

const Description = styled.p`
  font-size: 2.4rem;
  line-height: 1.3;
  margin-bottom: 7.5rem;
`

export default ({ children }: PropType) => <Description>{children}</Description>
