import React from 'react'
import styled from 'styled-components/macro'

import Icon from '../components/Icon'

type PropType = {
  children: JSX.Element | JSX.Element[]
  onClick?: any
  title?: string
}

const Modal = styled.div`
  background: rgba(0, 0, 0, 0.85);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`
const ModalContainer = styled.div`
  background: var(--color-initial);
  border-radius: var(--border-radius);
  left: 50%;
  max-width: calc(var(--max-width) / 1.5);
  min-height: 40rem;
  max-height: 90vh;
  max-width: 80rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`
const ModalHeader = styled.div`
  align-items: center;
  background: var(--color-primary);
  display: flex;
  height: 5rem;
  justify-content: center;
  position: relative;
`
const ModalTitle = styled.div`
  color: var(--color-initial);
  font-weight: var(--font-bold);
  text-transform: uppercase;
`
const ModalContent = styled.div`
  color: var(--color-tertiary);
  max-height: 90vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 4rem 3rem 6rem;
  position: relative;
`
const ModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  right: 2rem;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.3;
    transition: opacity 0.3s;
  }
  svg {
    fill: var(--color-initial);
  }
`

export default ({ children, onClick, title = '' }: PropType) => (
  <Modal>
    <ModalContainer>
      <ModalHeader>
        <ModalClose onClick={onClick}>
          <Icon type="close" />
        </ModalClose>
        {title && <ModalTitle>{title}</ModalTitle>}
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  </Modal>
)
