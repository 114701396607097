import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export default ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={({ location }) => (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    )}
  />
)
