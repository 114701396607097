import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components/macro'

import ErrorPage from '../pages/error'
import HomePage from '../pages/home'
import Tracker from '../utils/tracker'

const GlobalStyle = createGlobalStyle`
  :root {
    --border-radius: 3.0rem;

    --color-initial: #fff;
    --color-primary: #af4461;
    --color-secondary: #fae1e3;
    --color-tertiary: #7c7c7c;
    --color-quaternary: #dedede;
    --color-shadow: rgba(var(--color-primary), .15);

    --font-thin: 200;
    --font-regular: 400;
    --font-bold: 600;
    --font-black: 800;
    --font-primary: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    --font-secondary: 'Lucida Console', Monaco, monospace;

    --margin-bottom: 2.5rem;

    --max-width: 144.0rem;
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  body {
    background: var(--color-initial);
    font-size: 1.5rem;
    font-weight: var(--font-regular);
  }
`
const TrackRoute = ({ component: Component, ...rest }: any) => {
  Tracker(rest.location.pathname)

  return <Route {...rest} render={props => <Component {...props} />} />
}

export default () => (
  <BrowserRouter>
    <GlobalStyle />
    <Switch>
      <TrackRoute component={HomePage} exact path="/" />
      <TrackRoute component={ErrorPage} />
    </Switch>
  </BrowserRouter>
)
