import React from 'react'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Main from '../components/Main'

export default () => (
  <>
    <Header />
    <Main />
    <Footer />
  </>
)
