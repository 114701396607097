import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Container from '../components/Container'
import Icon from '../components/Icon'
import Link from '../components/Link'

const Header = styled.header`
  background: var(--color-initial);
  height: 8rem;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  .container {
    align-items: center;
    display: flex;
    height: 8rem;
    justify-content: space-between;
  }
`
const Logo = styled(Icon)`
  height: 16rem;
  position: relative;
  z-index: 1;
  &:hover {
    opacity: 0.5;
  }
`
const Navigation = styled.nav`
  align-items: center;
  background: var(--color-initial);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  &.false {
    display: none;
  }
  a {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 2rem;
    &:hover {
      opacity: 0.3;
    }
  }
  button {
    background: var(--color-initial);
    border-color: var(--color-primary);
    color: var(--color-primary);
    margin: 0;
    opacity: 1;
    &:hover {
      background: var(--color-primary);
      border-color: var(--color-primary);
      color: var(--color-initial);
      opacity: 1 !important;
    }
  }
  @media (min-width: 960px) {
    align-items: space-between;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    position: relative;
    &.false {
      display: flex;
    }
    a {
      margin: 0;
      margin-left: 2rem;
    }
  }
`
const Menu = styled(Icon)`
  cursor: pointer;
  fill: var(--color-primary);
  height: 3rem;
  position: relative;
  z-index: 1;
  @media (min-width: 960px) {
    display: none;
  }
`
export default () => {
  const [active, setActive] = useState(false)

  return (
    <Header>
      <Container>
        <Link href="/">
          <Logo type="logo" />
        </Link>
        <div>
          <Menu onClick={() => setActive(!active)} type="menu" />
          <Navigation
            className={String(active)}
            onClick={() => setActive(!active)}
          >
            <Link href="https://clube-de-vantagens.segonha.com.br/">
              Clube de Vantagens
            </Link>
            <Link href="/#quem-somos">Quem somos</Link>
            <Link href="/#como-funciona">Como funciona</Link>
            <Link href="/#cadastrar">
              <Button>Cadastrar</Button>
            </Link>
            {/* <Link href="https://app.segonha.com.br/login">
              <Button>Acessar</Button>
            </Link> */}
          </Navigation>
        </div>
      </Container>
    </Header>
  )
}
