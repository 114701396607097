import React from 'react'
import styled from 'styled-components/macro'

type PropType = {
  children: any
  className?: string
  id?: string
}

const Container = styled.div`
  max-width: 110rem;
  position: relative;
`

export default ({ children, className = '', id = '' }: PropType) => (
  <Container className={`container ${className}`.trim()} id={id}>
    {children}
  </Container>
)
