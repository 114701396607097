import React from 'react'
import styled from 'styled-components/macro'

type PropType = {
  children: string
}

const Title = styled.h2`
  color: var(--color-primary);
  font-weight: var(--font-bold);
  line-height: 2;
  margin: 0;
`

export default ({ children }: PropType) => <Title>{children}</Title>
