import api from '../utils/api'

export default (): [any] => {
  const handleSubmit = (event: any) => {
    event.preventDefault()
    api.setNewsletter(event.target.elements.email.value).then(result => {
      if (!result) return
      window.location.reload()
    })
  }

  return [handleSubmit]
}
