import React from 'react'
import styled from 'styled-components/macro'

import Cards from '../components/Cards'
import Container from '../components/Container'
import Description from '../components/Description'
import Title from '../components/Title'

const About = styled.div.attrs({
  id: 'quem-somos',
})`
  background: var(--color-secondary);
  color: var(--color-primary);
  padding-bottom: 10rem;
  padding-top: 10rem;
  text-align: center;
`

export default () => (
  <About>
    <Container>
      <Title children="Segonha" />
      <Description children="Queremos ajudar as gestantes e obstetras a darem o próximo passo" />
      <Cards />
    </Container>
  </About>
)
