import React from 'react'
import styled from 'styled-components/macro'

type PropType = {
  className?: string
  children: JSX.Element | JSX.Element[] | string | string[]
  onClick?: any
  size?: string
}

const Button = styled.button`
  background: var(--color-initial);
  border-color: var(--color-initial);
  border-radius: var(--border-radius);
  color: var(--color-primary);
  line-height: 3.4rem;
  text-transform: none;
  transition: all 0.3s;
  &:hover {
    background: var(--color-quaternary);
    border-color: var(--color-quaternary);
  }
`
const ButtonLarge = styled(Button)`
  height: 4.8rem;
  line-height: 4.6rem;
  padding: 0 5rem;
`
const ButtonSmall = styled(Button)`
  height: 2.4rem;
  line-height: 2.2rem;
  padding: 0 1.5rem;
`

export default ({ className = '', children, onClick, size = '' }: PropType) =>
  size === 'large' ? (
    <ButtonLarge className={className} onClick={onClick}>
      {children}
    </ButtonLarge>
  ) : size === 'small' ? (
    <ButtonSmall className={className} onClick={onClick}>
      {children}
    </ButtonSmall>
  ) : (
    <Button className={className} onClick={onClick}>
      {children}
    </Button>
  )
