import React from 'react'
import styled from 'styled-components/macro'
import Parser from 'html-react-parser'

const cards = [
  {
    description:
      'Sabemos que uma importante premissa de um relacionamento é a confiança. Garantimos o sigilo ético de todos os seus dados ou de suas pacientes guardadas no app, além de disponibilizar apenas informações com embasamento científico sob supervisão do Professor Dr. Edson Lucena (<a href="http://lattes.cnpq.br/8026288025235650" rel="noopener" target="_blank">Currículo Lattes</a>). Dê adeus a todas aquelas informações desencontradas que os palpiteiros de plantão espalham. Aqui só verdade!',
    title: 'Confiança',
    // image: 'https://via.placeholder.com/640x500',
  },
  {
    description:
      'Estamos sempre buscando alternativas mais práticas para o nosso dia-a-dia e agora as gravidinhas não ficarão para trás. O Segonha possibilita que a gestante tenha sempre em suas mãos o cartão pré natal em forma digital, atualizado e acompanhado por seu médico obstetra. De forma organizada e otimizada, disponibilizamos um cronograma gestacional com datas, lembretes e tudo o que for necessário para uma espera doce e tranquila.',
    title: 'Praticidade',
    // image: 'https://via.placeholder.com/640x500',
  },
  {
    description:
      'Estamos entrando em uma das fases mais importantes da vida de uma mulher. Gerar um bebê exige amor, carinho e cuidado. O Segonha vem para proporcionar conforto e segurança em cada fase dessa doce jornada, deixando as mamães acolhidas e seguras por toda a gestação. Nossa plataforma foi desenvolvida com muita atenção, tornando possível o acesso ao melhor conteúdo obstétrico. Deixa que a gente cuida de você!',
    title: 'Cuidado',
    // image: 'https://via.placeholder.com/640x500',
  },
]
const Cards = styled.div`
  a {
    color: var(--color-primary);
    &:hover {
      color: var(--color-tertiary);
    }
  }
  @media (min-width: 960px) {
    display: grid;
    grid-gap: 5rem;
    grid-template-columns: repeat(3, 1fr);
  }
`
const Card = styled.div`
  background: var(--color-initial);
  border-radius: 0.5rem;
  color: var(--color-tertiary);
  margin-bottom: 5rem;
  overflow: hidden;
  img {
    width: 100%;
  }
  div {
    padding: 3rem 3rem 4rem;
  }
  strong {
    display: block;
    font-size: 1.8rem;
    line-height: 2;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  @media (min-width: 960px) {
    margin-bottom: 0;
  }
`

export default () => (
  <Cards>
    {cards.map(({ description, title }, index) => (
      <Card key={index}>
        {/* <img alt={title} src={image} /> */}
        <div>
          <strong>{title}</strong>
          <span>{Parser(description)}</span>
        </div>
      </Card>
    ))}
  </Cards>
)
